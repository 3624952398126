<template>
  <v-app-bar app class="light-blue lighten-5">
    <v-app-bar-nav-icon @click.stop="drawerOpen = !drawerOpen"/>
    <v-toolbar-title>{{ $t('fields.username') }}: {{ user.username }}</v-toolbar-title>
  </v-app-bar>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  computed: {
    ...mapFields(['drawerOpen']),
    ...mapFields('auth', ['user']),
  },
};
</script>

<template>
  <v-navigation-drawer v-model="drawerOpen" app class="light-blue lighten-5" width="240">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          <v-img src="@/assets/logo.png"/>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider/>
    <menu-items v-if="user" :key="user.id"/>
  </v-navigation-drawer>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  components: {
    MenuItems: () => import(/* webpackChunkName: "menu-items" */ '../menu/MenuItems.vue'),
  },
  computed: {
    ...mapFields('auth', ['user']),
    ...mapFields(['drawerOpen']),
  },
};
</script>
